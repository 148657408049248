import { Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { PaymentMethod } from "const/paymentMethod";
import CardLayout from "components/layout/CardLayout";
import { ProviderUtils, TestProvider } from "utils/providerUtils";
import useLanguages from "hooks/useLanguages";

interface PaymentMethodSectionProps {
  paymentMethod: PaymentMethod;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod>>;
}

const PaymentMethodSection = ({
  paymentMethod,
  setPaymentMethod,
}: PaymentMethodSectionProps) => {
  const { isKorean, isJapanese } = useLanguages();

  const handlePaymentMethodChange = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    newPaymentMethod: PaymentMethod
  ) => {
    if (newPaymentMethod !== null) {
      setPaymentMethod(newPaymentMethod);
    }
  };

  const getHeader = () => {
    return (
      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
        <FormattedMessage id="payment.paymentMethodSelect" />
      </Typography>
    );
  };

  return (
    <CardLayout header={getHeader()}>
      <ToggleButtonGroup
        color="secondary"
        value={paymentMethod}
        exclusive
        onChange={handlePaymentMethodChange}
        size="small"
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 1,
          wordBreak: "keep-all",
        }}
      >
        {ProviderUtils.isHanpass && (
          <ToggleButton
            value={PaymentMethod.HANPASS}
            aria-label="hanpass wallet"
          >
            <FormattedMessage id="payment.hanpassWallet" />
          </ToggleButton>
        )}
        {ProviderUtils.isGME && (
          <ToggleButton value={PaymentMethod.GME} aria-label="GME pay">
            {isKorean ? "GME 페이" : "GME PAY"}
          </ToggleButton>
        )}
        {!ProviderUtils.isMame && (
          <ToggleButton value={PaymentMethod.CREDIT} aria-label="credit card">
            <FormattedMessage id="payment.creditCard" />
          </ToggleButton>
        )}
        {ProviderUtils.isMame && (
          <ToggleButton
            value={PaymentMethod.OVERSEAS}
            aria-label="Overseas card"
          >
            {isJapanese
              ? "海外カード"
              : isKorean
              ? "해외카드"
              : "Overseas card"}
          </ToggleButton>
        )}
        {[TestProvider.Dev, TestProvider.Local].includes(
          ProviderUtils.provider as TestProvider
        ) && (
          <>
            <ToggleButton value={PaymentMethod.TRANSFER} aria-label="Transfer">
              {isKorean ? "계좌이체" : "Transfer"}
            </ToggleButton>
            <ToggleButton value={PaymentMethod.PHONE} aria-label="Phone">
              {isKorean ? "휴대폰 결제" : "Phone"}
            </ToggleButton>
          </>
        )}
      </ToggleButtonGroup>
    </CardLayout>
  );
};

export default PaymentMethodSection;
