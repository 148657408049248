import { Routes, Route, Navigate } from "react-router-dom";
import RouteChangeTracker from "../routeChangeTracker";
import LoginPage from "pages/login-panel/LoginPage";
import LoginProviderPage from "pages/login-panel/LoginProviderPage";
import FindIdPage from "pages/login-panel/FindIdPage";
import FindPwPage from "pages/login-panel/FindPwPage";
import HanpassConsentPage from "pages/signup-hanpass-panel/HanpassConsentPage";
import PersonalInfoPage from "pages/personal-info-panel/PersonalInfoPage";
import OrderHistoryPage from "pages/ktx/order-history-panel/history-panel/OrderHistoryPage";
import PersonalInfoFormPage from "pages/personal-info-panel/edit-panel/PersonalInfoFormPage";
import RegistrationTab from "pages/signup-panel/RegistrationTab";
import CompletionPage from "pages/signup-panel/CompletionPage";
import HanpassCompletionPage from "pages/signup-hanpass-panel/HanpassCompletionPage";
import DetailQueryPage from "pages/ktx/order-history-panel/history-panel/DetailQueryPage";
import PaymentBookingPage from "pages/ktx/payment-panel/PaymentBookingPage";
import ConfirmationCard from "pages/login-panel/ConfirmationPage";
import NewPasswordPage from "pages/login-panel/NewPasswordPage";
import CustomerCenterPage from "pages/cs-panel/CustomerCenter";
import PrivateRoute from "./PrivateRoute";
import AnonymousRoute from "./AnonymousRoute";
import PassengerSelectPage from "pages/ktx/home-panel/PassengerSelectPage";
import NaverCallback from "components/social_login/NaverCallback";
import KaKaoCallback from "components/social_login/KakaoCallback";
import LineCallback from "components/social_login/LineCallback";
import GoogleCallback from "components/social_login/GoogleCallback";
import OAuthAdditionalInfo from "components/social_login/OAuthAdditionalInfo";
import TermsOfUse from "pages/consents/TermsOfUse";
import PrivacyPolicy from "pages/consents/PrivacyPolicy";
import PointHistory from "pages/personal-info-panel/PointHistory";
import RegisterPoint from "pages/personal-info-panel/RegisterPoint";
import PointParkComplete from "pages/pointpark/PointParkComplete";
import HomePage from "pages/ktx/home-panel/HomePage";
import StationListPage from "pages/ktx/station-panel/StationListPage";
import TrainListPage from "pages/ktx/train-list-panel/TrainListPage";
import AlimTalkTicket from "pages/tickets/AlimTalkTicket";
import SeatSelectionPage from "pages/ktx/seat-selection-panel/SeatSelectionPage";
import PaymentPage from "pages/ktx/payment-panel/PaymentPage";
import TicketPage from "pages/tickets/TicketPage";
import PaymentProcessingPage from "pages/ktx/payment-panel/PaymentProcessingPage";
import CreditCancelPage from "pages/ktx/payment-panel/CreditCancelPage";
import CreditPaymentPage from "pages/ktx/payment-panel/CreditPaymentPage";
import CardSignUp from "pages/card/CardSignUp";
import CardRoute from "./CardRoute";
import CardPageLayout from "pages/card/CardPageLayout";
import CardHome from "pages/card/CardHome";
import CardDetail from "pages/card/CardDetail";
import DepositWithdraw from "pages/card/Sections/DepositWithdraw";
import Transfer from "pages/card/Sections/Transfer";
import CardSettings from "pages/card/Sections/CardSettings";
import MyCards from "pages/card/MyCards";
import CardRegister from "pages/card/CardRegister";
import CardTransactions from "pages/card/CardTransactions";
import MyReservations from "pages/hanacard/MyReservations";

const AppBody = () => {
  RouteChangeTracker();

  return (
    <Routes>
      {/* [2024-03-08] 메인 페이지 및 기차 검색은 로그인 없이도 이용 가능하게 변경 */}
      <Route path="/" element={<HomePage />} />
      <Route path="/station-list" element={<StationListPage />} />
      {/* <Route path="/select-date" element={<DateSelectPage />} /> */}
      <Route path="/select-passengers" element={<PassengerSelectPage />} />
      <Route path="/search-results" element={<TrainListPage />} />
      <Route path="/login-provider" element={<LoginProviderPage />} />
      {/* consent */}
      <Route path="/usage-terms" element={<TermsOfUse />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/customer-center" element={<CustomerCenterPage />} />
      <Route path="/tickets/:ktxNumber" element={<AlimTalkTicket />} />

      <Route element={<AnonymousRoute />}>
        {/* TAE 로그인 페이지 라우팅, 로그인 상태 시 홈페이지로 리다이렉트 */}
        <Route
          path="/login"
          element={<LoginPage />}
          // element={isUserLoggedIn ? <Navigate to="/" /> : <LoginPage />}
        />
        {/* 회원가입 관련 페이지 라우팅 */}
        {/* <Route
          path="/sign-up"
          element={<ConsentPage />}
          // element={isUserLoggedIn ? <Navigate to="/" /> : <ConsentPage />}
        /> */}
        {/* <Route
          path="/information-entry"
          element={
            <InformationEntryPage />
            // isUserLoggedIn ? <Navigate to="/" /> : <InformationEntryPage />
          }
        /> */}
        <Route
          path="/hp-sign-up"
          element={<HanpassConsentPage />}
          // element={isUserLoggedIn ? <Navigate to="/" /> : <ConsentPage />}
        />
        <Route path="/confirmation" element={<ConfirmationCard />} />
        <Route
          path="/find-id"
          element={<FindIdPage />}
          // element={isUserLoggedIn ? <Navigate to="/" /> : <FindIdPage />}
        />
        <Route
          path="/find-password"
          element={<FindPwPage />}
          // element={isUserLoggedIn ? <Navigate to="/" /> : <FindPasswordPage />}
        />
        <Route path="/new-password" element={<NewPasswordPage />} />{" "}
        {/* 새 비밀번호 페이지 라우팅 */}
        <Route path="/register" element={<RegistrationTab />} />
        <Route path="/completion" element={<CompletionPage />} />
        <Route path="/completion-hanpass" element={<HanpassCompletionPage />} />
        {/* social login */}
        <Route path="/auth/naver" element={<NaverCallback />} />
        <Route path="/auth/kakao" element={<KaKaoCallback />} />
        <Route path="/auth/line" element={<LineCallback />} />
        <Route path="/auth/google" element={<GoogleCallback />} />
        <Route path="/auth/additional-info" element={<OAuthAdditionalInfo />} />
      </Route>

      <Route element={<PrivateRoute />}>
        {/* 홈페이지 라우팅 */}
        <Route
          path="/"
          // element={isUserLoggedIn ? <HomePage /> : <LoginPage />}
          element={<HomePage />}
        />

        {/* 기차 예약 및 조회 관련 페이지 라우팅 */}
        <Route
          path="/seat-selection/:trainNumber"
          element={<SeatSelectionPage />}
        />

        {/* 사용자 설정 및 주문 내역 관련 페이지 라우팅 */}
        <Route path="/user-settings" element={<PersonalInfoPage />} />
        <Route path="/user/point-history" element={<PointHistory />} />
        <Route path="/order-history" element={<OrderHistoryPage />} />
        <Route
          path="/order-details/:orderId"
          element={<PaymentPage isHistory={true} />}
        />
        <Route
          path="/order-details/:orderId/boarding-pass"
          element={<TicketPage />}
        />

        <Route path="/personal-info" element={<PersonalInfoFormPage />} />
        {/* <Route path="/search-station" element={<SearchStationPage />} /> */}
        <Route path="/detail-query" element={<DetailQueryPage />} />

        {/* 예약 정보 입력 페이지 라우팅 */}
        <Route path="/payment/booking" element={<PaymentBookingPage />} />

        {/* 롯데카드 결제 iframe 페이지 */}
        <Route
          path="/payment/process/:orderId"
          element={<PaymentProcessingPage />}
        />

        {/* 결제 확인 페이지 라우팅 */}
        <Route
          path="/payment/confirmation/:orderId"
          element={<PaymentPage isHistory={false} />}
        />
        <Route path="/train/creditCancel" element={<CreditCancelPage />} />
        <Route path="/train/creditPayment" element={<CreditPaymentPage />} />
        {/*<Route path="/new-password" element={<NewPasswordPage />} /> /!* 새 비밀번호 페이지 라우팅 *!/*/}

        {/* TODO: discount 적용되는 provider에서만 가능하도록 수정하기 */}
        <Route path="/user/register-coupon" element={<RegisterPoint />} />

        {/* Point Park */}
        <Route path="/pointpark/complete" element={<PointParkComplete />} />

        {/* 하나카드 my 에약 페이지 */}
        <Route path="/hanacard/reservations" element={<MyReservations />} />
        <Route
          path="/hanacard/order-details/:orderId"
          element={<PaymentPage isHistory={true} />}
        />

        {/* Card */}
        <Route path="/card/signup" element={<CardSignUp />} />
        <Route element={<CardRoute />}>
          <Route path="/card/*" element={<CardPageLayout />}>
            <Route path="" element={<CardHome />} />
            <Route path="detail" element={<CardDetail />}>
              <Route path="deposit" element={<DepositWithdraw />} />
              <Route path="transfer" element={<Transfer />} />
              <Route path="settings" element={<CardSettings />} />
            </Route>
          </Route>
          <Route path="/card/*">
            <Route path="list" element={<MyCards />} />
            <Route path="register" element={<CardRegister />} />
            <Route
              path=":cardNumber/transactions"
              element={<CardTransactions />}
            />
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppBody;
