import { Stack, Typography } from "@mui/material";
import CustomButton from "components/button/CustomButton";
import CardLayout from "components/layout/CardLayout";
import useFormatPrice from "hooks/useFormatPrice";
import useLanguages from "hooks/useLanguages";
import { FormattedMessage, useIntl } from "react-intl";
import { OrderType, PaymentInfo, RefundInfo } from "types/orderType";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PaymentService from "services/paymentService";

interface PaymentRefundInfoSectionProps {
  order: OrderType;
  type: "payment" | "refund";
  boxShadow?: boolean;
}

const PaymentRefundInfoSection = ({
  order,
  type,
  boxShadow = true,
}: PaymentRefundInfoSectionProps) => {
  const intl = useIntl();
  const { isKorean } = useLanguages();
  const formatPrice = useFormatPrice();

  const isPaidByCredit = Boolean(
    order.paymentInfo?.find((info) => info.paymentMethod === "Credit")
  );

  const navigateToReceiptPage = async () => {
    try {
      const response = await PaymentService.getReceiptUrl(order.orderId);
      // window.location.href = response;
      window.open(response, "_blank");
    } catch {
      alert("잠시 후 다시 시도해주세요.");
    }
  };

  const getHeader = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
          <FormattedMessage
            id={
              type === "payment" ? "orders.paymentTitle" : "orders.cancelInfo"
            }
          />
        </Typography>
        {isPaidByCredit && (
          <CustomButton
            onClick={navigateToReceiptPage}
            size="small"
            variant="text"
            fullWidth={false}
            // disabled={isLoading}
            style={{
              padding: 0,
              minHeight: "24px",
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {isKorean ? "영수증 출력" : "Print receipt"}
            </Typography>
            <NavigateNextIcon fontSize="small" />
          </CustomButton>
        )}
      </Stack>
    );
  };

  const getCategoryTitle = (
    paymentMethod: PaymentInfo["paymentMethod"] | RefundInfo["paymentMethod"]
  ) => {
    switch (paymentMethod) {
      case "Credit":
      case "Overseas":
        return intl.formatMessage({ id: "payment.creditCard" });
      case "Hanpass":
        return isKorean ? "한패스월렛" : "Hanpass Wallet";
      case "Pointpark":
        return isKorean ? "포인트파크" : "Pointpark";
      case "GME":
        return isKorean ? "GME 페이" : "GME Pay";
      default:
        const _exhaustiveCheck: never = paymentMethod;
        return _exhaustiveCheck;
    }
  };

  return (
    <CardLayout
      header={getHeader()}
      sx={{ width: "100%", boxShadow: boxShadow ? "" : "none" }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%" }}
      >
        <Typography variant="body1">
          <FormattedMessage
            id={
              type === "payment"
                ? "orders.totalPaymentAmount"
                : "orders.cancelAmount"
            }
          />
          :
        </Typography>
        <Typography
          variant="body1"
          color={type === "payment" ? "primary" : "error"}
          sx={{ fontWeight: "bold" }}
        >
          {formatPrice(order.totalPrice)}
        </Typography>
      </Stack>

      {(type === "payment" ? order.paymentInfo : order.refundInfo)?.map(
        (info) => {
          return (
            <Stack
              key={info.cardTransactionNumber}
              direction="row"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Typography variant="body1">
                {getCategoryTitle(info.paymentMethod)}:
              </Typography>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {formatPrice(info.paymentAmount)}
              </Typography>
            </Stack>
          );
        }
      )}
      {/* 라차 적립금 */}
      {Boolean(order.pointUsed) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">
            {intl.formatMessage({
              id: type === "payment" ? "orders.usedPoint" : "point.refundPoint",
            })}
            :
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {formatPrice(order.pointUsed)}
          </Typography>
        </Stack>
      )}
      {/* pointpark */}
      {Boolean(order.externalPointUsed) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">
            {isKorean ? "포인트파크" : "Pointpark"}:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {formatPrice(order.externalPointUsed)}
          </Typography>
        </Stack>
      )}
      {/* 한패스 쿠폰 */}
      {Boolean(order.externalCouponDiscount) && (
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">
            {isKorean ? "한패스 쿠폰" : "Hanpass coupon"}:
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {formatPrice(order.externalCouponDiscount)}
          </Typography>
        </Stack>
      )}
    </CardLayout>
  );
};

export default PaymentRefundInfoSection;
