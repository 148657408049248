import { Box, Typography, FormControlLabel, Radio, Grid } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Coupon } from "utils/reservationUtils";
import KakaoAccordion from "components/coupon/KakaoAccordion";
import CardLayout from "components/layout/CardLayout";
import useFormatPrice from "hooks/useFormatPrice";

interface CouponSectionProps {
  selectedCoupon: Coupon;
  handleCouponChange: (coupon: Coupon) => void;
  relatedGoods: Coupon[];
  description: string;
}

const CouponSection = ({
  selectedCoupon,
  handleCouponChange,
  relatedGoods,
  description,
}: CouponSectionProps) => {
  const intl = useIntl();
  const formatPrice = useFormatPrice();

  const getHeader = () => {
    return (
      <Box>
        <Typography variant="body1" sx={{ fontWeight: "bold" }} gutterBottom>
          <FormattedMessage id="booking.noTrainOnlyBooking" />
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: "bold" }} color="error">
          <FormattedMessage id="booking.vouchersDelivery" />
        </Typography>
      </Box>
    );
  };

  return (
    <CardLayout header={getHeader()}>
      <Typography variant="body1" sx={{ width: "95%" }}>
        {relatedGoods && relatedGoods[0]?.goodsName}
      </Typography>
      <Grid container alignItems="center" sx={{ width: "95%" }}>
        {relatedGoods?.map((good) => (
          <Grid key={`${good.goodsId}`} item xs={6}>
            <FormControlLabel
              control={
                <Radio
                  color="secondary"
                  checked={selectedCoupon?.goodsId === good.goodsId}
                  onChange={() => handleCouponChange(good)}
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", textAlign: "end" }}
                >{`${formatPrice(good.saleAmount)}`}</Typography>
              }
            />
          </Grid>
        ))}
      </Grid>
      <KakaoAccordion descriptionKey={description} />
    </CardLayout>
  );
};

export default CouponSection;
