import { Typography, Stack } from "@mui/material";
import { FormattedMessage } from "react-intl";
import useFormatPrice from "hooks/useFormatPrice";
import useGetOrderName from "hooks/useGetOrderName";
import { useNavigate } from "react-router-dom";
import { OrderType } from "types/orderType";
import CardLayout from "components/layout/CardLayout";
import ReservationDetails from "components/reservation/ReservationDetails";
import ShowTrainTicketButton from "components/reservation/ShowTrainTicketButton";

interface ProductInfoProps {
  order: OrderType;
}

const ProductInfoSection = ({ order }: ProductInfoProps) => {
  const formatPrice = useFormatPrice();
  const getOrderName = useGetOrderName();
  const navigate = useNavigate();

  const showTicket = () => {
    navigate(`/order-details/${order.orderId}/boarding-pass`, { state: order });
  };

  const getHeader = () => {
    return (
      <Typography variant="h6" sx={{ width: "100%" }}>
        {getOrderName(order.orderName)}
      </Typography>
    );
  };

  const getAdditionalInfo = () => {
    return (
      <>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            <FormattedMessage id="orders.price" />:
          </Typography>

          <Typography
            variant="h6"
            component="div"
            color="secondary"
            sx={{ fontWeight: "bold" }}
          >
            {formatPrice(Number(order.totalPrice) - order.goodsList.price)}
          </Typography>
        </Stack>

        <ShowTrainTicketButton
          status={order.orderStatus}
          onClick={showTicket}
        />
      </>
    );
  };

  return (
    <CardLayout header={getHeader()} additionalInfo={getAdditionalInfo()}>
      <ReservationDetails reservationDetails={order} />
    </CardLayout>
  );
};

export default ProductInfoSection;
