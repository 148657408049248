// export const PaymentMethod = {
//   CREDIT: "Credit",
//   HANPASS: "Hanpass",
// };

export enum PaymentMethod {
  CREDIT = "Credit",
  HANPASS = "Hanpass",
  GME = "GME",
  OVERSEAS = "Overseas",
  TRANSFER = "Transfer",
  PHONE = "Phone",
}

// export type PaymentMethodValue = `${PaymentMethod}`;
