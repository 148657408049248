import { Stack, Typography } from "@mui/material";

interface ReservationListIconProps {
  handleMenuClick: () => void;
}

const ReservationListIcon = ({ handleMenuClick }: ReservationListIconProps) => {
  return (
    <Stack
      onClick={handleMenuClick}
      justifyContent="center"
      alignItems="center"
      sx={{
        bgcolor: "#F14A77",
        color: "#fff",

        fontWeight: "bold",
        fontSize: "10px",

        position: "absolute",
        top: -4,
        right: -3,
        p: 0.6,
        px: 1,
        borderRadius: "4px",
        zIndex: 10,

        "&:before": {
          content: "''",
          position: "absolute",
          top: 13,
          right: 5,
          borderTop: "6px #F14A77",
          borderRight: "5px transparent",
          borderBottom: "6px transparent",
          borderLeft: "5px #F14A77",
          borderStyle: "solid",
          transform: "rotate(7deg)",
          zIndex: -10,
        },
      }}
    >
      <Typography
        variant="caption"
        sx={{
          lineHeight: 1,
          fontWeight: "bold",
          fontSize: "0.65rem",
        }}
      >
        예약내역
      </Typography>
    </Stack>
  );
};

export default ReservationListIcon;
