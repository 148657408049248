import { Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import useBottomSheet from "hooks/useBottomSheet";
import { useEffect, useState } from "react";
import BottomSheetHeader from "./BottomSheetHeader";
import { OrderType } from "types/orderType";
import { GMEPaymentRequestResponse } from "services/paymentService";
import GMEBottomSheetContent from "pages/ktx/payment-panel/sections/GMEBottomSheetContent";

export const HEADER_HEIGHT = 40;

// bottom sheet height
const calculateBottomSheetHeight = () => {
  return Math.max((window.innerHeight * 1) / 2, 440);
};

export const BOTTOM_SHEET_HEIGHT = calculateBottomSheetHeight();

// 바텀시트가 최대로 높이 올라갔을 때의 y 값
export const MIN_Y = BOTTOM_SHEET_HEIGHT;

// 바텀시트가 최소로 내려갔을 때의 y 값 === window의 height => 최소로 내려갔을때 안보이도록
// const MAX_Y = window.innerHeight;

interface BottomSheetProps {
  value: GMEPaymentRequestResponse;
  bottom: number;
  setBottom: React.Dispatch<React.SetStateAction<number>>;
  reservationDetails: OrderType;
}

const BottomSheet = ({
  value,
  bottom,
  setBottom,
  reservationDetails,
}: BottomSheetProps) => {
  // const [bottomSheetBottom, setBottomSheetBottom] = useState(bottom);
  const [bottomSheetHeight, setBottomSheetHeight] =
    useState(BOTTOM_SHEET_HEIGHT); // 바텀시트의 세로 길이

  const MIN_Y = window.innerHeight - bottomSheetHeight;
  const { sheet, content } = useBottomSheet(MIN_Y, bottomSheetHeight);

  // useLayoutEffect vs useEffect
  useEffect(() => {
    window.addEventListener("resize", () => {
      setBottomSheetHeight(calculateBottomSheetHeight);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setBottomSheetHeight(calculateBottomSheetHeight);
      });
    };
  });

  const resetBottomSheet = () => {
    setBottom(bottomSheetHeight * 2);
  };

  return (
    <BottomSheetWrapper
      ref={sheet}
      bottom={bottom}
      sx={{ height: bottomSheetHeight }}
    >
      <BottomSheetHeader height={HEADER_HEIGHT} />

      <GMEBottomSheetContent
        ref={content}
        value={value}
        resetBottomSheet={resetBottomSheet}
        reservationDetails={reservationDetails}
      />
    </BottomSheetWrapper>
  );
};

export default BottomSheet;

const BottomSheetWrapper = styled(Card)<{ bottom: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: fixed;
  z-index: 1;

  /* height: ${BOTTOM_SHEET_HEIGHT}px; */
  /* top: ${MIN_Y}px; */
  /* bottom: ${(props) => `${props.bottom}px`}; */
  bottom: 0;
  left: 0;
  right: 0;

  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  transform: ${(props) => `translateY(${props.bottom}px)`};

  transition: transform 150ms ease-out;
`;
