import { Stack } from "@mui/material";
import Layout from "components/layout/Layout";
import LoadingSpinner from "components/LoadingSpinner";
import OrderStatusSection from "components/order/OrderStatusSection";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import ProductInfoSection from "../history-detail-panel/sections/ProductInfoSection";
import RelatedProduct from "../history-detail-panel/sections/RelatedProduct";
import PaymentRefundInfoSection from "../history-detail-panel/sections/PaymentRefundInfoSection";
import { useLocation, useNavigate } from "react-router-dom";
import { OrderType } from "types/orderType";
import useCancelReservation from "hooks/useCancelReservation";

interface OrderDetailPageProps {
  reservationDetails: OrderType;
}

const OrderDetailPage = ({ reservationDetails }: OrderDetailPageProps) => {
  const navigate = useNavigate();
  const hanacard = useLocation().state?.hanacard ?? false;

  const isCancelled = reservationDetails?.orderStatus === "CANCELED";
  const isPurchased = reservationDetails?.orderStatus === "COMPLETE";

  const backToOrderList = useCallback(async () => {
    if (hanacard) {
      navigate("/hanacard/reservations");
      return;
    }

    // if (isPending && orderId) {
    //   await TrainService.cancelPoint(orderId);
    // }
    navigate("/order-history", { replace: true });
  }, [hanacard, navigate]);

  // cancel reservation
  const [CancelDetailsComponent] = useCancelReservation(reservationDetails);

  return (
    <Layout
      text={<FormattedMessage id="orders.detailTitle" />}
      onBack={backToOrderList}
    >
      {!reservationDetails ? (
        <LoadingSpinner />
      ) : (
        <Stack gap={1.5} sx={{ my: 2 }}>
          <OrderStatusSection order={reservationDetails} />
          <ProductInfoSection order={reservationDetails} />
          <RelatedProduct order={reservationDetails} />

          {isPurchased && (
            <>
              <PaymentRefundInfoSection
                type="payment"
                order={reservationDetails}
              />
              {/* 결제 완료한 경우 취소 예상 금액 보여주기 */}
              {CancelDetailsComponent}
            </>
          )}
          {isCancelled && reservationDetails.refundInfo && (
            <PaymentRefundInfoSection
              type="refund"
              order={reservationDetails}
            />
          )}
        </Stack>
      )}
    </Layout>
  );
};

export default OrderDetailPage;
