export const calculateCurrentDate = (): string => {
  const date = new Date();
  // return date.toISOString().split("T")[0];
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${date.getDate()}`;
};

export const calculateDateBeforeOneMonth = (): string => {
  const date = new Date();
  // date.setMonth(date.getMonth() - 1);
  date.setDate(date.getDate() - 31);
  return date.toISOString().split("T")[0];
};

export const calculateDateBeforeLong = (): string => {
  const date = new Date();
  date.setFullYear(1900);
  return date.toISOString().split("T")[0];
};

export const getCurrentDateString = (): string => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}${month}${day}`;
};

export const getOneMonthEarlierDateString = (): string => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}${month}${day}`;
};
