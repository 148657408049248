import React from "react";
import { FormattedMessage } from "react-intl";
import useTranslateStation from "./useTranslateStation";
import Arrow from "components/Arrow";
import TrainLogo from "components/TrainLogo";
import { Stack, Typography } from "@mui/material";
import useLanguages from "./useLanguages";

const useGetOrderName = () => {
  const { isKorean } = useLanguages();

  const translateStation = useTranslateStation();

  // 주문 이름 파싱
  const getOrderName = (orderName: string): React.ReactElement => {
    const orderNameWords = orderName.split(" ");
    const depart = translateStation(orderNameWords[1]);
    const arrive = translateStation(orderNameWords[3]);

    // let stlbTrnClsfCd;
    // switch (orderNameWords[0]) {
    //   case "KTX":
    //     stlbTrnClsfCd = "00";
    //     break;
    //   case "KTX-산천":
    //     stlbTrnClsfCd = "07";
    //     break;
    //   case "KTX-이음":
    //     stlbTrnClsfCd = "16";
    //     break;
    //   default:
    //     stlbTrnClsfCd = "00";
    // }

    return (
      <Stack
        direction={isKorean ? "row" : "column"}
        justifyContent={isKorean ? "space-between" : "center"}
        alignItems={isKorean ? "center" : "space-between"}
        gap={isKorean ? 1 : 0}
        sx={{
          fontWeight: "bold",
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <TrainLogo size="medium" />
          <Stack
            direction="row"
            alignItems="center"
            gap={0.2}
            sx={{
              width: "100%",
              textAlign: "center",
              wordBreak: "break-word",
            }}
          >
            <p>{depart} </p>
            <Arrow />
            <p>{arrive}</p>
          </Stack>
        </Stack>
        <Typography alignSelf="end" sx={{ fontWeight: "bold" }}>
          <FormattedMessage id="orders.oneWay" />
        </Typography>
      </Stack>
    );
  };

  return getOrderName;
};

export default useGetOrderName;
